import { config } from "./config";
import { push } from "./datalayer";
import {gti, trim, $id, _ef, $qsAll, $name} from "./helpers";
import {userTiming} from "./ga";
import { loadScript } from "./load_assets";
import {isIOS} from "./client_detection";
import {constants} from "./constants";
import {appendHTML} from "./dom";
import {on_page_unload} from "./on_page_unload";

let pageIsLoaded = false,
    events = {},
    jQueryLoaded = false,
    ready,
    onDocReady = function(){
        push({event: 'page ready'});
    },
    onload = function(forced){
        if (!pageIsLoaded && !config.pageEndTime){ //do not trigger this for forced loads based on unloads
            //Classy can sometimes double trigger window onload?
            pageIsLoaded = true;
            push({event: 'page loaded', forced: !!forced});
            userTiming('Page Loading', 'window.onLoad', gti(config.pageStartTime));
        }
    },
    unloadThrottle = function(type){
        if (config.pageEndTime) {
            return;
        }

        config.pageEndTime = gti();

        if (!pageIsLoaded){
            onload(true);
        }

        unload(type);
    },
    unload = _ef,
    vizchange = _ef;

let readyWait = setInterval(function() {
    let is_ready = ['complete', 'interactive'].indexOf((document.readyState+'').toLowerCase()) > -1,
        forced = gti(config.pageStartTime) > 1000*10 && !!document.body;

    if (is_ready || forced) {
        clearInterval(readyWait);
        ready = true;
        onDocReady(!is_ready && forced);
    }
}, 1);

function resetPageIsLoaded(){
    pageIsLoaded = false;

    onload = function(forced){
        pageIsLoaded = true;
        push({event: 'page loaded', forced: !!forced});
    };
}

function addLoadEvent(func) {
    if (pageIsLoaded) {
        return func();
    }

    let old = onload;
    onload = function(forced) {
        old(forced);
        func(forced);
    };
}

function addLoadEventWithTimeout(cb, timeout){
    let timed_out = false,
        fallback = setTimeout(function(){
            timed_out = true;
            cb();
        }, timed_out || 10000);

    addLoadEvent(function(){
        if (!timed_out){
            clearTimeout(fallback);
            cb();
        }
    });
}

function addVizEvent(func) {
    let old = vizchange;
    vizchange = function() {
        let state = document.visibilityState;
        old(state);
        func(state);
    };
}

function addUnLoadEvent(func) {
    let old = unload;
    unload = function(type) {
        old(type);
        func(type);
    };
}

function addReadyEvent(func) {
    if (ready) {
        return func();
    }

    let old = onDocReady;
    onDocReady = function(forced) {
        old(forced);
        func(forced);
    };
}

function processEvent(action, obj, evt, fn, opts){
    if (typeof evt === 'function'){
        fn = evt;
        evt = obj;
        obj = window;
    }

    if (typeof obj === 'string'){
        obj = $id(obj);
    }

    if (!obj) {
        return;
    }

    let legacy_event_check = obj[(action==='add'?'attach':'detach')+'Event'],
        prop = action+'EventListener';

    if (obj[prop]) {
        obj[prop](evt, fn, opts || false);
    }
    else if (legacy_event_check) {
        legacy_event_check('on' + evt, fn);
    }
}

function addEvent(obj, evt, fn, opts) {
    if (typeof evt === 'function'){
        opts = fn;
        fn = evt;
        evt = obj;
        obj = window;
    }

    if (typeof evt === 'string'){
        evt = [evt];
    }

    for (let i=0; i<evt.length; i++){
        processEvent('add', obj, evt[i], fn, opts);
    }
}

function wait(url, delay, target, rel) {
    on_page_unload('wait');

    setTimeout(function() {
        if (url.indexOf('http') !== 0) {
            if (url.indexOf('?') === 0 || url === ''){
                url = constants.PN+url;
            }

            url = constants.LN + '//' + constants.HN + url;
        }

        if (target) {
            let id = 'a' + gti();

            appendHTML({
                a: $name('body'),
                elem: 'a',
                id: id,
                href: url,
                target: target,
                rel: rel || null,
                'data-dnh': '1'
            });

            $id(id).click();
        }
        else {
            window.location.href = url;
        }
    }, delay || 0);
}

function removeEvent(obj, evt, fn) {
    processEvent('remove', obj, evt, fn);
}

function listenForEvent(evt, cb) {
    if (Array.isArray(evt)) {
        for (let i = 0; i < evt.length; i++) {
            listenForEvent(evt[i], cb);
        }

        return;
    }

    evt = trim(evt);

    if (typeof events[evt] === 'function') {
        let old = events[evt];
        events[evt] = function(msg) {
            old(msg);
            cb(msg);
        };
    } else {
        events[evt] = cb;
    }
}

function stopListeningForEvent(evt) {
    //TODO account for multiple listeners...
    delete events[evt];
}

function triggerEvent(evt, obj) {
    evt = trim(evt);
    if (typeof events[evt] === 'function') {
        events[evt]({type: evt, data: obj || {}});
    }
}

function fireEvent(event, target, detail, customEvent){
    if (typeof event === 'string'){
        event = event.replace(/ /g, '').toLowerCase().split(',');
    }

    let opts = {
        bubbles: true,
        isTrusted: true
    };

    if (detail){
        opts.detail = detail;
    }

    if (customEvent){
        //https://developer.mozilla.org/en-US/docs/Web/API/Event/Event
        //https://blog.logrocket.com/custom-events-in-javascript-a-complete-guide/
        opts.cancelable = false;
        opts.composed = true;
    }

    for (let i=0; i<event.length; i++){
        if (customEvent){
            if (target && typeof target.dispatchEvent === 'function'){
                target.dispatchEvent(new CustomEvent(event[i], opts));
            }
        }
            // else if (event[i].indexOf('key') === 0) {
            //     (target || window).dispatchEvent(new KeyboardEvent(event[i], opts));
        // }
        else if (typeof (target || window).dispatchEvent === 'function'){
            (target || window).dispatchEvent(new Event(event[i], opts));
        }
    }
}

function fireCustomEvent(event, detail){
    push({event: 'CustomEvent', name: event, detail: detail || null});
    fireEvent(event, window, detail || {}, true);
}

function triggerChange(elem, value){
    if (!elem || typeof value === 'undefined') {
        return;
    }

    //https://stackoverflow.com/questions/61107351/simulate-change-event-to-enter-text-into-react-textarea-with-vanilla-js-script
    //https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-change-or-input-event-in-react-js
    if (elem.tagName === 'TEXTAREA'){
        let nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value").set;
        nativeTextAreaValueSetter.call(elem, value);
        elem.dispatchEvent(new Event('input', { bubbles: true}));
    }

    if (elem.tagName === 'INPUT'){
        let nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(elem, value);

        let event = new Event('input', { bubbles: true });
        event.simulated = true;
        elem.dispatchEvent(event);
    }

    if (!config.is_classy_studio){
        elem.value = value;

        if (elem.tagName === 'TEXTAREA'){
            elem.innerText = value;
        }

        forceChange(elem);
    }
}

function forceChange(target) {
    fireEvent(['mouseenter', 'mouseover', 'mousedown', 'mouseup', 'click', 'select', 'focus', 'keydown', 'keypress', 'keyup', 'input', 'type', 'change', 'blur', 'mouseout'], target);
}

function jQueryOnLoad(callback) {
    let threshold_reached,
        jQueryCheck = setInterval(function() {
            if (ready){
                let span = gti(config.pageStartTime);

                if (window.jQuery || window.$) {
                    clearInterval(jQueryCheck);
                    jQueryLoaded = true;
                    callback(window.jQuery || window.$);
                }
                else if (config.main_site && span >= 6000 && !threshold_reached){
                    threshold_reached = true;
                    loadScript(config.cdnjs+'jquery/3.6.0/jquery.min.js');
                }
                else if (span >= 30000 && !threshold_reached){
                    threshold_reached = true;
                    clearInterval(jQueryCheck);
                }
            }
        }, 1);
}

function getAllEventListeners() {
    let allElements = Array.prototype.slice.call($qsAll('*')),
        types = [];

    allElements.push(document);
    allElements.push(window);

    for (let ev in window) {
        if (/^on/.test(ev)) {
            types[types.length] = ev;
        }
    }

    let elements = [];
    for (let i = 0; i < allElements.length; i++) {
        let currentElement = allElements[i];
        for (let j = 0; j < types.length; j++) {
            if (typeof currentElement[types[j]] === 'function') {
                elements.push({
                    "node": currentElement,
                    "type": types[j],
                    "func": currentElement[types[j]].toString(),
                });
            }
        }
    }

    return elements.sort(function(a,b) {
        return a.type.localeCompare(b.type);
    });
}

function listAllEventListeners(){
    console.table(getAllEventListeners());
}

function clarityReady(cb){
    if (typeof window.clarity === 'function'){
        return cb();
    }

    let wait = setInterval(function(){
        if (typeof window.clarity === 'function'){
            clearInterval(wait);
            return cb();
        }

        if (gti(config.pageStartTime) > 60000){
            clearInterval(wait);
        }
    }, 100);
}

let exit_intent_triggered = false,
    exit_intents = [];

function exitIntent(opts, cb){
    if (typeof opts === 'function'){
        cb = opts;
        opts = {};
    }

    opts = opts || {};

    opts.id = gti();
    opts.triggered = false;
    opts.callback = cb;

    exit_intents.push(opts);

    addEvent(document, 'mouseout', function(e) {
        e = e || window.event;

        let from = e.relatedTarget || e.toElement;
        if ((!from || from.nodeName === 'HTML') && (e.pageY < 1 || e.y < 1)) {
            exit_intent_triggered = true;

            for (let i=0; i<exit_intents.length; i++){
                if (!exit_intents[i].triggered || exit_intents[i].always){
                    exit_intents[i].triggered = true;
                    exit_intents[i].callback();
                }
            }
        }
    });
}

addEvent('load', function(e){
    if (!pageIsLoaded){
        onload();
    }
});

addEvent(document, 'visibilitychange', function(e){
    vizchange(e);
});

addEvent('pageshow', function(e){
    //https://web.dev/bfcache/#optimize-your-pages-for-bfcache
    //Looks like Drupal uses the `unload` event, so not really going to be able to optimize for bfcache for most pages anytime soon
    if (e.persisted) {
        GAEvent('track', {ec: 'bfcache'});
        console.log('This page was restored from the bfcache. You should run a GA pageview');
    }
});

//https://web.dev/bfcache/#test-to-ensure-your-pages-are-cacheable
addEvent('resume', function(){
    console.log('This page was resumed');
});

addEvent("offline", function(){
    config.online = false;
});

addEvent("online", function(){
    config.online = true;
});

//https://web.dev/bfcache/#only-add-beforeunload-listeners-conditionally
// if ('onpagehide' in window){
    addEvent('pagehide', function(event){
        //event.persisted /* the page isn't being discarded, so it can be reused later */

        if (event.persisted === false){
            unloadThrottle('pagehide');
        }

        //TODO: at some point
        // As a result, some browsers will not attempt to put a page in bfcache in the following scenarios:
        //
        // Pages with an open IndexedDB connection
        // Pages with in-progress fetch() or XMLHttpRequest
        // Pages with an open WebSocket or WebRTC connection
        //
        // If your page is using any of these APIs, it's best to always close connections and remove or disconnect observers during the pagehide or freeze event. That will allow the browser to safely cache the page without the risk of it affecting other open tabs.
    });
// }
// else {
    addEvent('beforeunload', function(){
        unloadThrottle('beforeunload');
    });
// }

// if (config.isMemTool || !('onpagehide' in window)){
    //https://web.dev/bfcache/#never-use-the-unload-event
    addEvent('unload', function(){
        unloadThrottle('unload');
    });
// }

if (isIOS()) {
    addEvent('blur', function(){
        unloadThrottle('blur');
    });
}

addReadyEvent(function(forced){
    setTimeout(function() {
        if (!pageIsLoaded) {
            onload(true);
            resetPageIsLoaded();
        }
    }, forced ? 2000 : 10000);
});

export {
    wait,
    addLoadEvent,
    addLoadEventWithTimeout,
    addVizEvent,
    addUnLoadEvent,
    addReadyEvent,
    triggerEvent,
    fireEvent,
    fireCustomEvent,
    triggerChange,
    forceChange,
    listenForEvent,
    stopListeningForEvent,
    processEvent,
    addEvent,
    removeEvent,
    onload,
    pageIsLoaded,
    resetPageIsLoaded,
    jQueryOnLoad,
    getAllEventListeners,
    listAllEventListeners,
    clarityReady,
    exitIntent,
    exit_intents
}