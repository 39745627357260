import { config } from "./config";
import "./vitals";
import {getNetworkReqs, resources, resourceTypes, listFonts} from "./resource_observer";
import {dataLayer, namespace, resurrected_dl, push} from "./datalayer";
import {SS_S, SS_G, readCookie, eraseCookie, LS_R, SS_R, createCookie, wuphf, getCookies, acceptsCookies} from "./storage";
import * as adestra from './adestra_auth';
import {addReadyEvent, addLoadEvent, addVizEvent, addUnLoadEvent, addEvent} from "./events";
import {is_touch_device, isBot} from "./client_detection";
import {$id, $name, dec, drupalSettingsOnLoad, getParentDomain, gti, gup, isInt, isUndefined, isUTMID, isUUID, trimObject, uuid} from "./helpers";
import {$qs, getHeadAttr, getMetaTags} from "./dom";
import * as ut from "./ut";
import * as lytics from "./lytics";
import {getPath, configure_ga4} from "./ga";
import * as _gtm from "./gtm";
import { setUser } from "./user";
import { custom_transfers, decorateLink } from "./transfers";
import * as ip from "./ip";
import {onSubmit} from "./on_submit";
import {ifIsInt} from "./validators";
import {loadScript, preconnect, preload, injectCSS} from "./load_assets";
import {clearChumsTesting} from "./tests";
import * as quicklink from 'quicklink/dist/quicklink'; //TODO coordinate with Build Team at some point to actually employ this
//import {runDataMonitor} from "./tests";
import * as cookie_consent from "./cookie_consent";
import {experiment, testBucket} from "./testing";
import {on_page_unload} from "./on_page_unload";

function createNewSessionDetails(){
    //assumes this is the start of the session
    //appends details about the page into the session object
    ut.setSess({
        new: 1,
        page: ut.getPage('id'),
        page_href: config.page,
        pages: 1,
        start: config.pageStartTime,
        last: config.pageStartTime,
        id: uuid()
    });

    let page_to_session = ['referrer', 'https', 'host', 'path', 'search', 'hash'];
    for (let j=0; j<page_to_session.length; j++){
        ut.setSess(page_to_session[j], ut.get('p', page_to_session[j]));
    }

    ut.incUser('sessions');

    dataLayer.push({
        event: 'sess_start'
    });
}

function updateSessionDetails(){
    ut.setSess('new', 0);
    ut.setSess('last', gti());
    ut.incSess('pages');
    ut.incUser('sessions');
}

export function clearDL(){
    let edf_dl = 'edf_datalayer';
    LS_R(edf_dl);
    SS_R(edf_dl);
    eraseCookie(edf_dl);
    config.dl_cleared = true;
    window.location.reload();
}

let movingOrScrolling = 0,
    movingTimeout,
    inScrolling,
    scrollTimeout,
    maxScrollPixels = 0,
    maxScrollPercentage = 0;

function onMove() {
    clearTimeout(movingTimeout);

    movingOrScrolling = 1;
    movingTimeout = setTimeout(function(){
        movingOrScrolling = 0;
    }, 5000);
}

function onScroll() {
    onMove();

    inScrolling = true;
    clearTimeout(scrollTimeout);

    scrollTimeout = setTimeout(function () {
        inScrolling = false;
    }, 150);

    let lastPercentage = Math.min(1, (window.innerHeight + window.pageYOffset) / document.body.offsetHeight),
        lastPixels = window.innerHeight + window.pageYOffset;

    if (lastPercentage > maxScrollPercentage) {
        maxScrollPercentage = lastPercentage;

        if (maxScrollPercentage > 100){
            maxScrollPercentage = 100;
        }

        config.engagement.scrollDepth = Math.round(maxScrollPercentage*100);
    }

    if (lastPixels > maxScrollPixels) {
        maxScrollPixels = lastPixels;

        config.engagement.scrollPixels = maxScrollPixels;
    }
}

addVizEvent(function(state){
    // if (state === 'prerender'){ //check prerender http://www.js-craft.io/blog/the-page-visibility-api-in-javascript/
    //
    // }

    if (state === 'hidden'){
        ut.send_queue();
    }

    config.pageIsVisible = state !== 'hidden';

    dataLayer.push({
        event: 'visibilitychange',
        state: state,
        tp: gti(config.pageStartTime)
    });
});

addUnLoadEvent(on_page_unload);

function getSproutSocialSource(){
    let ref = document.referrer,
        source = 'sprout_social';

    if (ref){
        if (ref.indexOf('fb') > -1 || ref.indexOf('facebook') > -1){
            source = 'facebook';
        }
        else if (ref.indexOf('tw') > -1 || ref.indexOf('t.co') > -1 || ref.indexOf('tco') > -1){
            source = 'twitter';
        }
        else if (ref.indexOf('link') > -1){
            source = 'linkedin';
        }
    }

    return source;
}

export function edf_init(){
    function checkFBCookies(){
        ut.setSess('_fbc', readCookie('_fbc'));
        ut.setSess('_fbp', readCookie('_fbp'));
    }

    if (config.isTesting && (config.isEA || config.isAdestra)){
        if (cookie_consent.hasConsentCookie()){
            config.useCookieConsent = true; //respect users previously set with cookie preference
        }
        else {
            let id = 'Ac0',
                name = 'cookie_consent_mem_test',
                bucket = testBucket(id);

            experiment({
                name: name,
                experiment_id: id,
                group: bucket
            });

            if (bucket !== 'control'){
                config.useCookieConsent = true;
            }
        }
    }

    if (config.useCookieConsent){
        cookie_consent.init();
    }

    if (config.DNT){
        createCookie('dnt', '1', 0);
    }
    else {
        eraseCookie('dnt');
    }

    let test_cookie_name = 'chums',
        test_cookie_value = 'testing',
        isTesting = 'isTesting';

    if (gup(isTesting) === 'false'){
        clearChumsTesting();
    }
    else if (config[isTesting]) {
        createCookie(test_cookie_name, test_cookie_value, 0, true);
        SS_S(test_cookie_name, test_cookie_value);
    }
    else if (SS_G(test_cookie_name) === test_cookie_value || readCookie(test_cookie_name) === test_cookie_value) {
        config[isTesting] = true;
    }

    if (config[isTesting]){
        //runDataMonitor();
    }

    if (!config.isMethaneSatData){
        if (!isBot && !config.DNT && !config.GPC && acceptsCookies()){
            preconnect('www.googletagmanager.com');

            if (config.GAOBJ[config.HN] === config.GAPROD){
                preconnect(['www.clarity.ms', 'bat.bing.com', 'connect.facebook.net', 'ad.doubleclick.net', 'px.ads.linkedin.com']);
            }
        }
    }

    //TODO remove
    if (config.main_site){
        if (!window.jQuery){
            preload(config.cdnjs+'jquery/3.6.0/jquery.min.js');
        }
    }

    injectCSS('img[width="1"][height="1"]{display:none;}');

    adestra.init();

    if (!config.DNT && !config.GPC && !config.isFilloutEmbed){
        ip.lookup();
        ip.enrich();
    }

    //Delete deprecated cookies and LS items
    let remove = ['edf_cid'];

    for (let i=0; i<remove.length; i++){
        wuphf(remove[i], null, -1);
    }

    (function(){
        let transfer = gup('custom_transfer'),
            sid = gup('ut_sid');

        let nav_type = window.performance && performance.navigation ? performance.navigation.type : null, //TODO update to https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/type
            nav_type_value = null;

        if (config.isInClassyEmbed){
            nav_type = -1;
            nav_type_value = 'classy_embed';
        }
        else if (nav_type === 0){
            nav_type_value = 'navigate';
        }
        else if (nav_type === 1){
            nav_type_value = 'reload';
        }
        else if (nav_type === 2){
            nav_type_value = 'back_forward';
        }
        else if (nav_type === 255){
            nav_type_value = 'other';
        }

        ut.setPage({
            page_href: config.page,
            nav_type: nav_type,
            nav_type_value: nav_type_value,
            'loc.referrer': config.docReferrer,
            'loc.https': config.LN === 'https:' ? 1 : 0,
            'loc.host': config.HN,
            'loc.path': config.PN,
            'loc.search': config.QS.replace('?', ''),
            'loc.hash': config.HS.replace('#', '')
        });

        if (isInt(transfer) && gti(parseInt(transfer)) < 1000 * 60 && isUUID(sid)){
            let last_page_id = gup('ut_pid');
            if (isUUID(last_page_id)){
                ut.setPage('last_page_id', last_page_id);
            }

            if (resurrected_dl.session.id && resurrected_dl.session.last && gti() - resurrected_dl.session.last < 1000*60*30){
                ut.setSess(resurrected_dl.session);
            }

            ut.setSess('id', sid);

            updateSessionDetails();

            let utm_id = gup('ut_id');
            if (isUTMID(utm_id)){
                ut.setSess('utm_id', utm_id);
            }
        }
        else {
            if (resurrected_dl.session.id && resurrected_dl.session.last && gti() - resurrected_dl.session.last < 1000*60*30){
                ut.setSess(resurrected_dl.session);
                updateSessionDetails();
            }
            else {
                createNewSessionDetails();
            }
        }

        if (resurrected_dl.page.id && !ut.getPage('last_page_id')){
            ut.setPage('last_page_id', resurrected_dl.page.id);
        }

        ut.setUser(resurrected_dl.user);

        if (!ut.getUser('edf_uuid') && !config.DNT && acceptsCookies()){
            loadScript({url: config.utility_path + 'me', format: 'jsonp'}, function(err, json) {
                if (!err && json && typeof json === 'object' && json.uuid){
                    ut.setUser('edf_uuid', json.uuid);
                }
            });
        }

        ut.setSess('internal_source_code', gup('internal_source_code') || gup('isc') || gup('campaign_code'));
        ut.setSess('wave_code', gup('wave_code') || gup('wave'));
        ut.setSess('contact_channel', gup('contact_channel') || gup('channel'));
        ut.setSess('admin', dataLayer[0].session.admin || (config.isAdmin ? 1 : 0));
        ut.setSess('internal', !!(readCookie('edfinsider') || ut.getSess('admin') || ut.getUser('drupal_uid')));
        ut.setSess('touch', is_touch_device() ? 1 : 0);
        ut.delUser('touch');

        if (typeof ut.getSess('v_secs') !== 'number'){
            ut.setSess('v_secs', 0);
        }

        if (typeof ut.getSess('a_secs') !== 'number'){
            ut.setSess('a_secs', 0);
        }

        let url_builder_ = 'url_builder_',
            url_builder_owner = url_builder_+'owner',
            url_builder_tactic = url_builder_+'tactic',
            url_builder_target = url_builder_+'target',
            url_builder_cta = url_builder_+'cta',
            url_builder_funnel = url_builder_+'funnel',
            ub_o = ifIsInt(gup('ub_o')),
            ub_tc = ifIsInt(gup('ub_tc')),
            ub_tg = ifIsInt(gup('ub_tg')),
            ub_cta = ifIsInt(gup('ub_cta')),
            funnel_bucket = gup('ub_f') || gup('ub_b') || gup('ub_fb'),
            fbclid = gup('fbclid'),
            dclid = gup('dclid');

        ut.setSess(url_builder_owner, ub_o);
        ut.setSess(url_builder_tactic, ub_tc);
        ut.setSess(url_builder_target, ub_tg);
        ut.setSess(url_builder_cta, ub_cta);

        if (['b', 'm', 't'].indexOf((funnel_bucket+'').toLowerCase()) > -1){
            funnel_bucket = funnel_bucket.toUpperCase()+'OFU';
            ut.setSess(url_builder_funnel, funnel_bucket);
        }

        if (fbclid){
            ut.setSess('_fbc', 'fb.1.'+gti()+'.'+fbclid); //https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
        }

        if (dclid){
            ut.setSess('dclid', dclid);
        }

        checkFBCookies();

        addLoadEvent(function(){
            setTimeout(checkFBCookies, 4000);
        });

        if (config.isProd && !ut.getSess('new') && gup('chums') !== 'testing'){
            clearChumsTesting();
        }
    })();

    if (config.isDrupal){
        drupalSettingsOnLoad(function(drupalSettings){
            if (drupalSettings){
                if (drupalSettings.user && drupalSettings.user.uid){
                    config.drupalUID = drupalSettings.user.uid;
                    ut.setUser('drupal_uid', config.drupalUID);
                }

                if ((drupalSettings.path.currentPath+'').indexOf('node/') > -1){
                    ut.setPage('nid', drupalSettings.path.currentPath.replace('node/', ''));
                }
            }
        });
    }

    setUser();

    (function(utm_campaign, utm_source, utm_medium, utm_id, utm_content, utm_term) {
        let gup_campaign = gup(utm_campaign),
            gup_source = gup(utm_source),
            gup_medium = gup(utm_medium),
            gup_id = gup(utm_id),
            gup_term = gup(utm_term),
            gup_content = gup(utm_content);

        if (gup_source && gup_medium) {
            ut.setSess(utm_campaign, gup_campaign);
            ut.setSess(utm_source, gup_source);
            ut.setSess(utm_medium, gup_medium);
            ut.setSess(utm_id, gup_id);
            ut.setSess(utm_term, gup_term);
            ut.setSess(utm_content, gup_content);
        }

        //overrides
        let override = true,
            medium,
            source,
            campaign,
            smt_campaign = 'edf_none_upd_smt',
            social_media = 'social-media',
            campaign_id = gup('campaign_id', config.page),
            launch_id = gup('launch_id', config.page),
            email_variant = gup('email_variant', config.page);

        if (gup_medium === 'email'){
            if (campaign_id && launch_id){
                medium = gup_medium+'-'+campaign_id+'-'+launch_id;
            }
            else if (campaign_id){
                medium = gup_medium+'-'+campaign_id;
            }
            else if (launch_id){
                medium = gup_medium+'-'+launch_id;
            }

            if (medium && email_variant){
                medium = medium+'-'+email_variant;
            }
        }
        else if ((gup_source === 'feedburner' || (gup_source === 'twitter' && gup_term === '1503494270')) && isUTMID(gup_term)) {
            ut.setSess(utm_id, gup_term);
            ut.delSess(utm_term);
        }
        //overriding sprout social entries
        else if (isUTMID(gup_content) && gup_medium === 'social' && !gup_id && config.social_sources.indexOf(gup_source.toLowerCase()) > -1){
            ut.setSess(utm_id, gup_content);
            ut.delSess(utm_content);

            medium = social_media;
            source = gup_source.toLowerCase();
            campaign = smt_campaign;
        }
        else if (gup_source === '{SOCIAL_NETWORK}'){ //https://www.edf.org/you-are-not-alone-we-deal-climate-anxiety-too?utm_source={SOCIAL_NETWORK}&utm_medium=social&utm_content={TIMESTAMP_EPOCH}
            source = getSproutSocialSource();
            medium = social_media;
            campaign = smt_campaign;

            ut.delSess(utm_content);
        }
        else if (gup_medium === 'social' && (gup_source+'').split(',').length > 1){
            source = getSproutSocialSource();
            medium = social_media;
            campaign = smt_campaign;

            if (isUTMID(gup_content)){
                ut.setSess(utm_id, gup_content);
                ut.delSess(utm_content);
            }
        }
        else if (gup_content === 'urban-legend' && gup_term){
            campaign = gup_term;
            source = gup_content;

            ut.setSess(utm_campaign, gup_term);
            ut.setSess(utm_source, gup_content);
        }
        else if (gup_term === 'urban-legend' && gup_content){
            campaign = gup_content;
            source = gup_term;

            ut.setSess(utm_campaign, gup_content);
            ut.setSess(utm_source, gup_term);
        }
        else {
            override = false;
        }

        if (override) {
            ut.setSess(utm_source, source || gup(utm_source));
            ut.setSess(utm_campaign, campaign || gup(utm_campaign));
            ut.setSess(utm_medium, medium || gup(utm_medium));
        }
    })('utm_campaign', 'utm_source', 'utm_medium', 'utm_id', 'utm_content', 'utm_term');

    (function(source, sub_source) {
        //not sure where pageNotFound is set besides in GA callback
        let a,
            //clean up
            temp = gup('addl_info'),
            temp2 = readCookie('addl_info');

        createCookie(config.custom[0], config.HN + getPath() + config.QS, 0);

        let time = gti(),
            transfer = gup('custom_transfer'),
            transferred = false;

        if (transfer && time - parseInt(transfer) < 1000 * 60 * 2) {
            transferred = true;
            let read = readCookie('acid');

            temp = config.custom[0];
            temp2 = gup(temp);
            try {
                temp2 = dec(gup(temp));
            } catch(e){}

            if (temp2) {
                createCookie(temp, temp2, 0);
            }

            temp = config.custom[1];
            temp2 = gup(temp);
            try {
                temp2 = dec(gup(temp));
            } catch(e){}
            
            if (!readCookie(temp) && temp2) {
                createCookie(temp, temp2, 0);
            }

            for (let i = 2; i < 5; i++) {
                temp = config.custom[i];
                temp2 = gup(temp);
                if (!readCookie(temp) && temp2 && !read) {
                    createCookie(temp, dec(temp2));
                }
            }

            a = config.custom[5];
            temp2 = gup(a);
            if (temp2) {
                temp = readCookie(a);
                createCookie(a, temp && temp.indexOf(temp2) === -1 ? temp + '_' + dec(temp2) : dec(temp2), 0);
            }

            temp = gup('custom_source');
            if (temp) {
                createCookie(source, dec(temp), 0);
            }

            temp = gup('custom_sub_source');
            if (temp) {
                createCookie(sub_source, dec(temp), 0);
            }
        }

        if (!transferred) {
            if (config.docReferrer) {
                let an = document.createElement('a');
                an.href = config.docReferrer;
                config.docHost = an.hostname.replace(/%2F/g, '');

                if (config.docHost.indexOf(':') !== -1) {
                    temp = config.docHost.split(':');
                    if (temp.length === 2 && isInt(temp[1])) {
                        config.docHost = temp[0];
                    }
                }
                if (typeof an.pathname === 'string') {
                    config.docPath = an.pathname.replace(/%2F/g, '');
                }

                config.docPath = config.docPath || 'blank';
            }
            else {
                config.docHost = 'direct (' + config.HN + ')';
                config.docPath = '(blank)';
            }

            temp = config.custom[1];
            if (!readCookie(temp)) {
                createCookie(temp, config.HN + getPath(), 0);
            }

            temp = config.custom[2];
            if (!readCookie(temp) && !readCookie('acid')) {
                createCookie(temp, config.HN + getPath());
            }
        }

        temp = readCookie(source);
        if (temp) {
            config.src = temp;
        }
        else {
            config.src = ut.getSess('utm_source') || gup(source) || gup('s_src') || null;

            if (!config.src) {
                config.src = config.docHost ? config.docHost.indexOf('t.co') === 0 ? 'twitter' : config.docHost : config.HN + ' (default)';
            }

            createCookie(source, config.src, 0);
        }

        temp = readCookie(sub_source);
        if (temp) {
            config.subsrc = temp;
        }
        else {
            config.subsrc = ut.getSess('utm_campaign') || gup(sub_source) || gup('s_subsrc') || null;

            if (!config.subsrc) {
                config.subsrc = config.docPath ? config.docPath : getPath() + ' (default)';
            }

            createCookie(sub_source, config.subsrc, 0);
        }

        ut.setSess('web_source', config.src);
        ut.setSess('web_sub_source', config.subsrc);

        dataLayer[0].source = !isUndefined(dataLayer[0].source) ? dataLayer[0].source : (window.src || null);
        dataLayer[0].sub_source = !isUndefined(dataLayer[0].sub_source) ? dataLayer[0].sub_source : (window.subsrc || null);

        for (let c = 0; c < config.custom.length; c++) {
            let prop = config.custom[c];
            dataLayer[0][prop] = typeof dataLayer[0][prop] !== 'undefined' ? dataLayer[0][prop] : (readCookie(prop) || null);
        }

        let utm_mapping = [['utm_source', 'utm_s'], ['utm_medium', 'utm_m'], ['utm_campaign', 'utm_c'], ['utm_id', 'utm_i'], ['utm_content', 'utm_t']];
        for (let c = 0; c < utm_mapping.length; c++) {
            dataLayer[0][utm_mapping[c][0]] = typeof dataLayer[0][utm_mapping[c][0]] !== 'undefined' ? dataLayer[0][utm_mapping[c][0]] : (readCookie(utm_mapping[c][1]) || null);
        }

        if (!transferred) {
            temp = config.custom[3];
            if (!readCookie(temp) && !readCookie('acid') && config.src) {
                createCookie(temp, config.src);
            }

            temp = config.custom[4];
            if (!readCookie(temp) && !readCookie('acid') && config.subsrc) {
                createCookie(temp, config.subsrc);
            }

            a = config.custom[5];
            temp = gup(a);
            if (temp) {
                temp2 = readCookie(a);
                createCookie(a, temp2 && temp2.indexOf(temp) === -1 ? temp2 + '_' + temp : temp, 0);
            }
        }

        if ((!config.isClassy || config.is_classy_studio) && transfer && window.history && window.history.replaceState) {
            let new_page = (config.PN + config.QS + config.HS).replace('?&', '?');
            window.history.replaceState({}, document.title, new_page);
        }
    })('source', 'sub_source');

    addReadyEvent(function(){
        let body_classes_to_add = [config.HN, 'site-'+(getParentDomain(config.HN)||'').replace('.', '')];

        if ((config.main_site && (config.HN.indexOf('platform') > -1 || config.HN.indexOf('tugboat') > -1))
            || (config.isBlogs && config.HN.indexOf('pantheon') > -1)
        ){
            body_classes_to_add.push('site-edforg');
        }

        for (let prop in config){
            if (prop.indexOf('is') === 0 && prop.length > 2 && config[prop] === true){
                body_classes_to_add.push(prop);
            }
        }

        requestAnimationFrame(function(){
            $name('body').classList.add(...body_classes_to_add);
        });

        let title = document.title,
            english_title = $qs('meta[name="english:title"]');

        ut.setPage({
            page_status: getHeadAttr('data-status'),
            page_title: ((english_title ? english_title.getAttribute('content') : title) || title).split(' | ')[0],
            meta: getMetaTags()
        });

        if (config.main_site) {
            config.main_site_refresh = document.body.className.split(' ').indexOf('edf') === -1; //add as dimension // data-pack
        }

        let nid = getHeadAttr('data-nid'),
            classes = getHeadAttr('class');

        let generator = $qs('meta[name="generator"]');
        if (generator){
            generator = (generator.getAttribute('content') || '').toLowerCase();

            if (generator.indexOf('wordpress') > -1){
                config.isWordpress = true;
            }
            else if (generator.indexOf('drupal') > -1){
                config.isDrupal = true;
            }
        }
        else if ($qs('[data-drupal-selector]')){
            config.isDrupal = true;
        }

        if (!nid) {
            if (config.isWordpress) {
                if (classes){
                    classes = classes.split(' ');
                    for (let j = 0; j < classes.length; j++) {
                        if (classes[j].indexOf('page-id-') === 0) {
                            nid = classes[j].replace('page-id-', '');
                            break;
                        }
                    }
                }

                if (!nid){
                    let body_class = $name('body').className;
                    if (body_class){
                        if (body_class.indexOf('page-id-') > -1){
                            nid = body_class.split('page-id-')[1].split(' ')[0];
                        }
                        else if (body_class.indexOf('post-id-') > -1){
                            nid = body_class.split('post-id-')[1].split(' ')[0];
                        }
                        else if (body_class.indexOf('postid-') > -1){
                            nid = body_class.split('postid-')[1].split(' ')[0];
                        }
                    }
                }
            }
            else if (config.isClassyEmbed && gup('cid')){
                nid = gup('cid');
            }
            else if (config.isClassy && config.classy_props.campaign_id) {
                nid = config.classy_props.campaign_id;
            }
            else if (config.isAdestra) {
                let body_classes = document.body.className.split(' ');
                for (let i=0; i<body_classes.length; i++){
                    let test = 'amf-survey-';
                    if (body_classes[i].indexOf(test) > -1){
                        nid = body_classes[i].replace(test, '').trim();
                    }
                }
            }
        }

        nid = nid || '-1';
        ut.setPage('nid', parseInt(nid));

        if (!isBot){
            if (config.isDev && config.PN.indexOf('/have-climate-anxiety') === 0) {
                config.loadPF = true;
            }

            lytics.configure();
            configure_ga4();
            onSubmit();
        }

        onMove();

        addEvent('scroll', onScroll, { passive: true });

        addEvent(document.getElementsByTagName('html')[0], ['mousemove', 'keypress', 'click', 'touchstart'], onMove);

        setInterval(function(){
            function findIndexOfUppercase(str) {
                for (let i = 0; i < str.length; i++) {
                    if (str[i] >= 'A' && str[i] <= 'Z') {
                        return i;
                    }
                }

                return -1;
            }

            config.engagement.rawTime = Math.round(gti(config.pageStartTime)/1000);
            config.engagement.totalTime++;

            if (movingOrScrolling) {
                config.engagement.attentionTime++;
                ut.incSess('a_secs');
            }

            if (config.pageIsVisible){
                config.engagement.visibleTime++;
                ut.incSess('v_secs');
            }
            else {
                config.engagement.hiddenTime++;
            }

            let a_secs = ut.getSess('a_secs'),
                v_secs = ut.getSess('v_secs'),
                eng_obj = {
                    ...$EDF.config.engagement
                };

            //shaving off chars to pack into event label
            for (let prop in eng_obj){
                let name = prop[0],
                    upper = findIndexOfUppercase(prop);

                if (upper > -1){
                    name = name+prop[upper];
                }

                eng_obj[name] = eng_obj[prop];
                delete eng_obj[prop];
            }

            eng_obj = JSON.stringify({
                ...eng_obj,
                sat: a_secs,
                svt: v_secs
            });

            if ([30, 60, 90, 120, 180, 600].indexOf(a_secs) > -1){
                GAEvent('session_attention_secs_'+a_secs, {
                    ec: 'engagement',
                    el: eng_obj
                });
            }

            if ([60, 120, 180, 600].indexOf(v_secs) > -1){
                GAEvent('session_visible_secs_'+v_secs, {
                    ec: 'engagement',
                    el: eng_obj
                });
            }

            a_secs = config.engagement.attentionTime;
            v_secs = config.engagement.visibleTime;

            if ([30, 60, 90, 120, 180, 600].indexOf(a_secs) > -1){
                GAEvent('page_attention_secs_'+a_secs, {
                    ec: 'engagement',
                    el: eng_obj
                });
            }

            if ([60, 120, 180, 600].indexOf(v_secs) > -1){
                GAEvent('page_visible_secs_'+v_secs, {
                    ec: 'engagement',
                    el: eng_obj
                });
            }
        }, 1000);
    });

    addLoadEvent(function(){
        // injectionDetection();

        push({event: 'load'});

        if ($qs('iframe[src*="youtube"]') || $qs('iframe[src*="vimeo"]')){
            config.page_has_video_embed = true;
            ut.setPage('has_video_embed', true);
        }

        if (!isBot && !config.limitData && config.isTesting && config.isDev && config.main_site && !config.isAdmin){
            let noprefetch = 'noprefetch';
            if (!gup(noprefetch) && config.HS.indexOf(noprefetch) === -1){
                //CAUTION this can generate ambiguous errors when the fetched link is 404 or redirect
                quicklink.listen({
                    el: $id('main-content') || $id('content'),
                    limit: 5,
                    throttle: 2,
                    delay: 1000,
                    timeout: 1500,
                    threshold: .2,
                    ignores: [
                        /\/admin\/?/,
                        /\/ajax\/?/,
                        /\/user\/?/,
                        /\/donate\/?/,
                        /mailto:/,
                        /javascript:/,
                        (uri, elem) => elem.hasAttribute(noprefetch),
                        (uri, elem) => (elem.className+'').toLowerCase().indexOf('ajax') > -1,
                        (uri) => {
                            uri = uri.split('?')[0];
                            if (uri.indexOf('.') === -1){
                                return false;
                            }

                            uri = uri.split('.')[1];

                            return new RegExp(config.exts).test(uri);
                        }
                    ],
                    onError: console.error
                });
            }
        }

        if (!ut.allow() || config.isGDPR || isBot || config.limitData || config.DNT || config.isMethaneSatData){
            return;
        }

        setTimeout(function(){
            function fingerprint(){
                Fingerprint2.get(function(components) {
                    let values = [];
                    for (let i=0; i<components.length; i++){
                        values.push(components[i].value)
                    }

                    ut.setUser('device_id', Fingerprint2.x64hash128(values.join(''), 31));
                    push({event: 'fpjs'});
                });
            }

            if (ut.get('u', 'device_id')){
                return push({event: 'fpjs'});
            }

            loadScript(config.cdnjs+'fingerprintjs2/2.1.0/fingerprint2.min.js', function(){
                if (window.requestIdleCallback) {
                    requestIdleCallback(fingerprint);
                }
                else {
                    setTimeout(fingerprint, 500);
                }
            });
        }, 500);

        /*XMLHttpRequest.prototype.realSend = XMLHttpRequest.prototype.send;
        XMLHttpRequest.prototype.send = function(value) {
            this.addEventListener("progress", function(){
                console.log("Loading. Here you can intercept...");
            }, false);

            this.realSend(value);
        };*/
    });

    if (!isBot && !config.isAdmin) {
        addReadyEvent(function(){
            setTimeout(custom_transfers, 500);
        }); //giving delay to prevent conflicts with link re-writes in clicker()

        addLoadEvent(custom_transfers);
    }

    dataLayer[0] = trimObject(dataLayer[0]);

    window[namespace][0] = dataLayer[0];

    if (config.DNT || config.GPC || !acceptsCookies()){
        //https://developers.google.com/tag-platform/tag-manager/web/restrict
        dataLayer.push({'gtm.allowlist' : []});  //TODO include DNT in GTM container and handle there, and drop this exclusion. Not urgent.
    }

    if (!isBot && !config.isFilloutEmbed){
        _gtm.loadDefault();
    }
}

export {
    custom_transfers,
    decorateLink,
    getNetworkReqs,
    resources,
    resourceTypes,
    listFonts
}