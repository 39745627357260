import { config } from "./config";
import {dataLayer, namespace} from "./datalayer";
import { ajaxReq } from "./ajax_req";
import { pixeler } from "./load_assets";
import {copyObj, gti, hasSha512, sha512, stripNonUTF, trimObject, uuid, viewport, getConnection, isUTMID, isEmail} from "./helpers";
import {createCookie, readCookie, LS_S, SS_S, wuphf, are_cookies_enabled, acceptsCookies, SS_G, getCookies} from "./storage";
import { NR } from "./new_relic";
import {isBot, getDevicePixelRatio, supportsBeacon} from "./client_detection";
import * as Base64 from './base64';
import {_gtag_set} from "./ga";
import * as lytics from "./lytics";
import * as gtm from "./gtm";
import {getFirstPaint} from "./first_paint";

//todo avoid image

let user = 'user',
    session = 'session',
    page = 'page',
    endpoint = config.utility_path+'hit',
    payload_queue = [],
    payloads_sent = [],
    payloads_not_sent = {
        blocked: [],
        not_sent: []
    },
    unloading,
    events_to_send_immediately = config.conversion_events.concat(['unload']),
    events_for_lytics = config.conversion_events.concat(['zero_party_collection', 'consent']),
    allowed_events = events_to_send_immediately.concat(['page_view', 'virtual_pageview', 'event', 'load', 'timing', 'error', 'sess_start', 'sess_ex', 'payloads_blocked', 'exception', 'sentry', 'new_relic', 'ecommerce', 'zero_party_collection', 'gtm_loaded', 'gtm_not_loaded', 'web_vital', 'consent', 'classy_response']),
    props_to_strip = ['loc', 'id', 'start', 'nid', 'engagement_page_id', 'last_page_id', 'pushed_from_ut', 'ut', 'pushed_from_window', 'nav_type', 'nav_type_value', 'events', 'bypass_ga4', 'bypass_ga', 'page_location', 'page_path', 'page_title', 'timestamp'],
    hasWithCredentials = 'withCredentials' in new XMLHttpRequest();

let queue_interval = setInterval(send_queue, 1000*15);

let ps = 0, //payloads_sent
    tps = 0, //total_payloads_sent
    block_payloads = false,
    payloads_interval = setInterval(function(){
        if (ps > 150){
            if (!block_payloads){
                dataLayer.push({
                    event: 'payloads_blocked',
                    ps: ps,
                    tps: tps
                });

                SS_S('edf_bps', 1);
                block_payloads = true;
            }

            clearInterval(payloads_interval);
        }
        else {
            ps = 0;
        }
    }, 1000*30);

document.addEventListener('visibilitychange', function(){
    if (document.visibilityState === 'hidden'){
        send_queue();
    }
});

function allow(){
    return !config.DNT && ((config.GAOBJ[config.HN] && !config.isUtility)
        || config.isLocal || config.isMemTool || config.isMicroSite || config.isDevHosting || config.isGCA || config.isFRI || config.isTFCI || config.isCBJ || config.isInsider || config.isCBNS);
}

function getQueue(){
    return copyObj(payload_queue);
}

function getSent(str){
    if (typeof str !== 'string'){
        return copyObj(payloads_sent);
    }

    let arr = [];
    for (let i=0; i<payloads_sent.length; i++){
        let evt = JSON.stringify(payloads_sent[i]);
        if (evt.toLowerCase().replace(/ /g, '').indexOf(str.toLowerCase().replace(/ /g, '')) > -1){
            arr.push(payloads_sent[i]);
        }
    }

    return arr;
}

function getNotSent(){
    return copyObj(payloads_not_sent);
}

function send_queue(){
    if (payload_queue.length){
        if (config.pageEndTime || unloading) {
            let queued;

            if (supportsBeacon()){
                try {
                    queued = navigator.sendBeacon(endpoint, JSON.stringify(payload_queue));
                } catch(e){}
            }

            if (!queued){
                for (let i=0; i<payload_queue.length; i++){
                    pixeler({url: endpoint, data: payload_queue[i]}); //can always run this on top of sendBeacon if sendBeacon isn't reliable enough
                }
            }
        }
        else {
            send_payload(payload_queue);
        }

        payloads_sent = payloads_sent.concat(payload_queue);
        payload_queue = [];
    }
}

function post_payload(hit){
    ajaxReq({
        url: endpoint,
        method: 'POST',
        data: hit,
        body_format: 'json'
    });
}

function send_payload(hit){
    //https://xgwang.me/posts/you-may-not-know-beacon/#it-may-throw-error%2C-be-sure-to-catch
    if (!supportsBeacon()) {
        return post_payload(hit);
    }

    let queued;

    try {
        queued = navigator.sendBeacon(endpoint, JSON.stringify(hit)); //NOTE: a failed request might show as pending in network console
    } catch(e){}

    if (!queued) {
        post_payload(hit);
    }
}

function start_send(hit, sendImmediate) {
    if (config.pageEndTime || unloading || (!supportsBeacon() && !hasWithCredentials)) {
        payload_queue.push(hit);
        return send_queue();
    }

    if (payload_queue.length > 4){ //reducing from 4 to 5 to account for increasing sizes of payloads and given data size limits of beacon requests
        send_queue();
    }

    payload_queue.push(hit);

    if (sendImmediate){
        send_queue();
    }
}

function push(pushed_event){
    setSess('last', gti());

    if (typeof pushed_event !== 'object' || isBot) {
        return;
    }

    let ev = copyObj(pushed_event);

    if (!ev){
        return;
    }

    if ((ev.event+'').indexOf('gtm.') === 0) {
        return;
    }

    if ((ev.send_to+'').indexOf('G-') === 0 && ev.send_to !== 'G-'+config.ga4_stream[0]){
        return; //do not duplicate on GA4 events
    }

    // if (typeof ev.sample === 'number'){
    //     if (ev.sample > 1){
    //         ev.sample = ev.sample/100;
    //     }
    //
    //     if (ev.sample > 0){
    //         if (ev.sample >= Math.random()){
    //             return;
    //         }
    //     }
    // }

    // https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters
    // https://madoveranalytics.com/featured/cookies/759/

    let sendHit = !ev.excludeHit,
        sendImmediate,
        addPageDimensions = true,
        page = getPage(),
        session = getSess(),
        user = getUser(),
        connection = getConnection(),
        hit = {},
        send_to_lytics = false;

    if (ev.pivot_to_event_for_ut){
        ev.ea = ev.event;
        ev.event = 'event';
    }
    delete ev.pivot_to_event_for_ut;

    if (ev.event === 'hit') {
        hit = {
            t: ev.t,
            m: ev.m || null,
            tid: ev.tid,
            _gid: ev._gid || null,
            _r: ev._r || null,
            _s: ev._s || null,
            _u: ev._u || null,
            _v: ev._v || null,
            gjid: ev.gjid || null,
            jid: ev.jid || null,
            gclid: ev.gclid || null
        };

        // if (ev.t === 'pageview') {
        //     let conversion_pg = 'conversion_pg';
        //     setSess(conversion_pg, config.current_page);
        //     setSess(conversion_pg+'_id', getPage('id'));
        //
        //     if (session.new){
        //         hit.ns = 1;
        //         hit.touch = session.touch;
        //         delete session.new;
        //     }
        // }

        if (ev.t === 'event') {
            hit.en = incPage('events');
            hit.ec = ev.ec;
            hit.ea = ev.ea;
            hit.el = ev.el;
            hit.ev = ev.ev;
            hit.ni = ev.ni;

            if (ev.cd25 === 'excludeHit'){
                sendHit = false;
            }
        }
        else if (ev.t === 'transaction'){
            hit.ti = ev.ti;
            hit.tr = parseFloat(ev.tr || null);
            hit.ta = ev.ta;

            if (isNaN(hit.tr)){
                sendHit = false;
            }
        }
        else if (ev.t === 'timing'){
            hit.utc = ev.utc;
            hit.utl = ev.utl || null;
            hit.utt = parseInt(ev.utt);
            hit.utv = ev.utv || null;

            sendHit = false;
        }
        else if (ev.t === 'item') {
            sendHit = false;
        }
    }
    else {
        //NOTE: payload types are whitelisted on back-end in payloads_mapping
        if (events_to_send_immediately.indexOf(ev.event) > -1){
            sendImmediate = true;
        }

        if (typeof hit.lytics === 'object'){
            send_to_lytics = {...hit.lytics};
            delete hit.lytics;
        }
        else if (events_for_lytics.indexOf(ev.event) > -1){
            send_to_lytics = true;
        }

        hit.uid = ev.uid || null;
        hit.m = ev.m || 'dl';
        delete ev.uid;
        delete ev.m;

        if (allowed_events.indexOf(ev.event) > -1 || ev.ut === true){
            if (ev.event === 'unload'){
                unloading = true;
            }

            ['ec', 'ea', 'el', 'ev', 'af', 'at'].forEach(function(prop){
                if (prop in ev){
                    hit[prop] = ev[prop];
                    delete ev[prop];
                }
            });

            ['event_category', 'event_label', 'event_value', 'event_index', 'action_focus', 'action_type'].forEach(function(prop){
                if (prop in ev){
                    let abbreviated_prop = prop.split('_');
                    hit[abbreviated_prop[0][0]+abbreviated_prop[1][0]] = ev[prop];
                    delete ev[prop];
                }
            });
        }
        else {
            sendHit = false;
        }

        hit.props = copyObj(ev);
        delete hit.props.event;

        hit.t = ev.event;

        if (hit.t === 'purchase'){
            hit.t = 'transaction';
        }

        delete hit.event;
    }

    hit._ga = readCookie('_ga') || null;
    hit.sd = window.screen.pixelDepth;
    hit.dpr = getDevicePixelRatio();
    hit.de = document.inputEncoding;
    hit.sw = window.screen.width;
    hit.sh = window.screen.height;
    hit.vw = viewport().width;
    hit.vh = viewport().height;
    hit.ul = navigator.language;
    hit.svd = config.saveData ? 1 : 0;
    hit.rtt = connection.rtt || null; //https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/rtt
    hit.eft = connection.effectiveType || null;
    hit.cnt = connection.type || null;
    hit.dl = connection.downlink || null;
    hit.dom = document.getElementsByTagName('*').length; //https://web.dev/dom-size-and-interactivity/
    hit.chars = document.documentElement.innerHTML.length;

    hit.tv = config.V;
    hit.uid = hit.uid || uuid();
    hit.props = hit.props || {};
    hit.props.gtul = readCookie('googtrans') || null;

    if (document.body){
        hit.props.body_classes = document.body.className.replace(/ /g, '|') || null;
        if (hit.props.body_classes && hit.props.body_classes.split('|').length > 30){
            hit.props.body_classes.split('|').slice(0, 30).join('|');
        }
    }

    hit.props.ljs = config.isLegacyJS ? 1 : 0;

    //common properties
    hit.dnt = config.DNT ? 1 : 0;
    hit.ace = are_cookies_enabled() ? 1 : 0;
    hit.aca = acceptsCookies() ? 1 : 0;
    hit.ckc = Object.keys(getCookies()).length;
    hit.ckl = document.cookie.length;
    hit._ct = '';
    hit.z = gti();
    hit.tzo = (new Date().getTimezoneOffset())/60;
    hit.ga4 = typeof window.gtag === 'function' ? 1 : 0;
    hit.gtm = gtm.loaded ? 1 : 0;

    let tz = new Date().toLocaleTimeString('en-us', {timeZoneName:'short'}).split(' ');
    hit.tz = tz.length > 2 ? tz[2] : null;

    hit.meta = page.meta || null;
    hit.pid = page.id || null;
    hit.lpid = page.last_page_id || null;
    hit.pn = page.pages;
    hit.puid = page.engagement_page_id || null;
    hit.ctid = page.nid;

    hit.gsid = session.ga4_session_id || session.ga_session_id || null;

    //elements that stick to redis key server-side for session expiration sniffing
    hit.sid = session.id || null;
    hit.ss = session.start;
    hit.sl = session.last;
    hit.spid = session.page;
    hit.spd = session.pages;
    hit.wb = session.webteam_traffic;
    hit.em = session.email_traffic;
    hit.mtf = session.marketing_traffic;
    hit.pi = session.persona_id || null;
    hit.pg = session.persona_group || null;

    hit.ovi = user.variant_id || null;

    hit.cid = ev.cid || user.ga4_client_id || user.ga_client_id || user.cid || null;
    hit.tmp = user.temp_id || null;
    hit.e = user.email || null;
    hit.aid = user.p2a_advocate_id || null;
    hit.agid = user.adestra_guid || null;
    hit.acid = user.adestra_contact_id || null;
    hit.consid = user.cons_id || null;
    hit.clmid = user.classy_member_id || null;
    hit.clsid = user.classy_supporter_id || null;
    hit.duid = user.drupal_uid || null;
    hit.did = user.device_id || null;
    hit._fbp = session._fbp || null;
    hit.dclid = session.dclid || null;
    hit.luid = user.lytics_uid || null;
    hit.seg = user.lytics_segments || null;

    hit.nt = page.nav_type || null;

    if (page.loc){
        hit.dr = page.loc.referrer || null;
        hit.ds = page.loc.https || null;
        hit.dh = page.loc.host || null;
        hit.dp = page.loc.path || null;
        hit.dq = page.loc.search || null;
        hit.dhs = page.loc.hash || null;
    }

    hit.ad = session.admin || 0;

    if (session.internal || user.drupal_uid){
        hit.internal = 1;
    }

    hit.alid = session.adestra_launch_id || null;
    hit.acd = session.adestra_campaign_id || null;
    hit.wc = session.wave_code || null;
    hit.dfc = session.contact_channel || null;
    hit.isc = session.internal_source_code || null;

    hit.cs = ev.cs || session.utm_source || null;
    hit.cn = ev.cn || session.utm_campaign || null;
    hit.cm = ev.cm || session.utm_medium || null;
    hit.ci = ev.ci || session.utm_id || null;
    hit.cc = ev.cc || session.utm_content || null;
    hit.ck = ev.ck || session.utm_term || null;

    hit.no = session.network_organization || null;
    hit.nc = session.network_category || null;
    hit.nd = session.network_domain || null;
    hit.np = session.network_provider || null;
    hit.ct = session.connection_type || null;
    hit.as = session.network_as || null;
    hit.asn = session.network_asn || null;
    hit.nlt = session.network_latitude || null;
    hit.nlg = session.network_longitude || null;
    hit.nco = session.network_country_code || null;
    hit.nrc = session.network_region_code || null;
    hit.ncc = session.network_continent_code || null;
    hit.ntz = session.network_timezone || null;
    hit.nto = session.network_timezone_offset || null;

    //hit.co = session.asn_country || null;
    hit.intv = typeof user.is_international !== "undefined" ? user.is_international : (session.is_international || null);

    hit.fp = getFirstPaint() || null;

    if (hit.t === 'timing'){
        let utt = typeof hit.utt !== 'undefined' ? hit.utt : ev.utt;
        hit.utc = hit.utc || ev.utc || null;
        hit.utl = hit.utl || ev.utl || null;
        hit.utt = parseInt(utt);
        hit.utv = hit.utv || ev.utv || null;
    }

    if (acceptsCookies()){
        hit.cookies = getCookies();

        for (let cookie in hit.cookies){
            //filter out CSRF-TOKEN, XSRF-TOKEN and anything with `token` in the cookie name
            if (cookie.toLowerCase().indexOf('token') > -1){
                delete hit.cookies[cookie];
            }
        }
    }

    if (addPageDimensions){
        for (let prop in page){
            if (!props_to_strip.includes(prop) && prop.indexOf('gtm.') !== 0){
                if (['fcp', 'ttfb', 'fid', 'lcp', 'cls', 'inp', 'meta'].includes(prop)){
                    hit[prop] = page[prop];
                }
                else {
                    hit.props[prop.toLowerCase()] = page[prop];
                }
            }
        }

        if (window.engagement && typeof engagement.getData === 'function'){
            let data = engagement.getData();
            hit.eatt = data.attention || 0;
            hit.ecp = data.content_progress || 0;
            hit.esd = data.scroll_depth || 0;
            hit.etp = data.time_on_page || 0;
            hit.eng = data.engagement || 0;
            hit.enc = data.engagement_clicks || 0;
        }

        let performance = window.performance;
        if (performance){
            let timing = performance.timing;
            if (timing){
                let navStart = timing.navigationStart,
                    loadEventEnd = timing.loadEventEnd;

                if (loadEventEnd){
                    hit.pdm = timing.domComplete - navStart;  //DOM ready time
                    hit.pld = loadEventEnd - navStart;        //page load time
                }

                hit.props.pt = timing;
            }

            let memory = performance.memory;
            if (memory){
                hit.hl = memory.jsHeapSizeLimit || null;
                hit.hs = memory.totalJSHeapSize || null;
                hit.hu = memory.usedJSHeapSize || null;
            }
        }
    }

    //removes nulls //TODO standardize with trim obj and keep _ct
    for (let prop in hit){
        if (hit[prop] === null || typeof hit[prop] === 'undefined' || hit[prop]+'' === '(empty)' || hit[prop]+'' === 'null' || hit[prop]+'' === '(not set)'){
            delete hit[prop];
        }
        else if (props_to_strip.includes(prop) || prop.indexOf('gtm.') === 0){
            delete hit[prop];
        }
        else if (prop === 'props' && typeof hit[prop]){
            for (let sub in hit[prop]){
                if (hit[prop][sub] === null || typeof hit[prop][sub] === 'undefined'){
                    delete hit[prop][sub];
                }
                else if (props_to_strip.includes(sub) || sub.indexOf('gtm.') === 0){
                    delete hit[prop][sub];
                }
            }
        }
        else if (typeof hit[prop] === 'string'){
            hit[prop] = stripNonUTF(hit[prop]).trim();

            if (!hit[prop] && prop !== '_ct'){
                delete hit[prop];
            }
        }
    }

    if (typeof hit.props === 'object'){
        for (let prop in hit.props){
            if (prop === 'goals' && hit.props[prop] === 0){
                delete hit.props[prop];
            }
            else if (typeof hit.props[prop] === 'string'){
                hit.props[prop] = stripNonUTF(hit.props[prop]).trim();

                if (!hit.props[prop]){
                    delete hit.props[prop];
                }
            }
        }

        if (Object.keys(hit.props).length === 0){
            delete hit.props;
        }
    }

    window[namespace][0] = dataLayer[0];

    if (!config.dl_cleared){
        let edf_dl = 'edf_datalayer',
            dl = {
                page: {
                    id: dataLayer[0].page.id //set page id for last page id on next page
                },
                session: copyObj(dataLayer[0].session),
                user: copyObj(dataLayer[0].user)
            };

        dl = Base64.encode(JSON.stringify(dl));

        //stashes in storage
        LS_S(edf_dl, dl);
        SS_S(edf_dl, dl);

        //stashes last resort cookie
        //corresponds to mapping on page load discovery
        let cookie = {
            i: hit.sid,
            s: hit.start,
            l: hit.sl,
            p: hit.spid,
            n: hit.spd,
            z: hit.pid,
            w: hit.wb,
            e: hit.em,
            f: hit.mtf,
            c: hit.consid || null,
            a: hit.acid || null,
            b: hit.aid || null,
            m: hit.clmid || null,
            y: hit.clsid || null,
            t: hit.tmp || null,
            wc: hit.wc || null,
            isc: hit.isc || null,
            cc: hit.dfc || null,
            u: hit.ci || null,
            v: hit.ovi || null,
            pi: hit.pi,
            pg: hit.pg
            //d: hit.did || null
        };

        cookie = trimObject(cookie);

        createCookie(edf_dl, Base64.encode(JSON.stringify(cookie)));
    }

    block_payloads = SS_G('edf_bps') || block_payloads;

    if ((!allow() && !ev.allow_override) || block_payloads || !sendHit){
        if (block_payloads){
            payloads_not_sent.blocked.push(hit);
        }
        else {
            payloads_not_sent.not_sent.push(hit);
        }

        return;
    }

    tps++;
    ps++;

    if (send_to_lytics){
        lytics.event(typeof send_to_lytics === 'object' ? send_to_lytics : {
            event: hit.t,
            event_category: hit.ec,
            event_type: hit.at,
            event_focus: hit.af,
            event_label: hit.el
        });
    }

    start_send(hit, sendImmediate);
}

function getEntity(entity){
    if (entity === 'p'){
        entity = page;
    }
    else if (entity === 's'){
        entity = session;
    }
    else if (entity === 'u'){
        entity = user;
    }

    return entity;
}

function get(entity, prop){
    let obj = {};

    if (Array.isArray(dataLayer) && dataLayer.length && dataLayer[0][getEntity(entity)]){
        obj = copyObj(dataLayer[0][getEntity(entity)]);
    }

    if (typeof prop === 'undefined'){
        return obj;
    }

    return obj[prop];
}

function getUser(prop){
    return get('u', prop);
}

function getSess(prop){
    return get('s', prop);
}

function getPage(prop){
    return get('p', prop);
}

function set(entity, prop, val){
    entity = getEntity(entity);

    if (typeof prop === 'string'){
        let prop_str = prop;
        prop = {};
        prop[prop_str] = typeof val === 'undefined' ? null : val
    }

    let value;

    prop = {...prop};

    dataLayer[0][entity] = dataLayer[0][entity] || {};

    for (let i in prop){
        if (entity === 'page' && i.split('.').length === 2){
            let nest = i.split('.');

            dataLayer[0][entity][nest[0]] = dataLayer[0][entity][nest[0]] || {};
            value = typeof prop[i] === 'undefined' || prop[i] === '' || (prop[i] && typeof prop[i] === 'object' && Object.keys(prop[i]).length === 0) ? null : prop[i];
            dataLayer[0][entity][nest[0]][nest[1]] = value;
        }
        else {
            value = typeof prop[i] === 'undefined' || prop[i] === '' || (prop[i] && typeof prop[i] === 'object' && Object.keys(prop[i]).length === 0) ? null : prop[i];

            if (entity === user && i === 'variant_id'){
                let existing_prop = getUser(i);
                if (existing_prop && (existing_prop+'').indexOf(value) === -1){
                    dataLayer[0][entity][i] = [existing_prop, value].join(',');
                }
                else if (!existing_prop) {
                    dataLayer[0][entity][i] = value;
                }
            }
            else {
                dataLayer[0][entity][i] = value;
            }
        }

        if (entity === user && value !== null){
            if (i === 'device_id'){
                NR.setAttr(i, prop[i]);
            }
            else if (i === 'email'){
                let email = (prop[i]+'').toLowerCase().trim();

                if (isEmail(email)){
                    lytics.setEmail(email);

                    if (hasSha512()){
                        sha512(email).then(function(hash){
                            setUser(i, hash);
                            NR.setAttr(i, hash);
                        });
                    }
                    else {
                        delete dataLayer[0].user.email;
                    }
                }
            }
            else if (i === 'adestra_contact_id'){
                NR.setUid(prop[i]);
                NR.setAttr(i, prop[i]);
                wuphf('acid', prop[i], 10000);
                _gtag_set({adestra_contact_id: prop[i]});
            }
            else if (i === 'classy_member_id'){
                NR.setAttr(i, prop[i]);
                wuphf('clmid', prop[i], 10000);
                _gtag_set({classy_member_id: prop[i]});
            }
            else if (i === 'cons_id'){
                NR.setAttr(i, prop[i]);
                dataLayer[0].cons_id = prop[i];
            }
            else if (i === 'p2a_advocate_id'){
                NR.setAttr(i, prop[i]);
            }
            else if (i === 'drupal_uid'){
                NR.setAttr('drupal_uid', prop[i]);
            }
        }

        if (entity === session && value !== null){
            if (i === 'utm_id'){
                _gtag_set({utm_id: prop[i]});

                if (isUTMID(prop[i])) {
                    createCookie(config.custom[6], prop[i], 0);
                }
            }

            let url_builder_ = 'url_builder_'
            if ([url_builder_+'owner', url_builder_+'tactic', url_builder_+'target', url_builder_+'cta', url_builder_+'funnel', 'utm_id', 'utm_source', 'utm_campaign', 'utm_medium', 'utm_content', 'utm_term'].indexOf(i) > -1){
                lytics.setProperty(i, prop[i]);
            }

            if (i.indexOf('utm_') === 0){ //utm_* parameters
                dataLayer[0][i] = prop[i];

                _gtag_set({
                    [i.replace('campaign', 'name').replace('utm', 'campaign')]: prop[i]
                });
            }
        }
    }

    window[namespace][0] = dataLayer[0];
}

function setUser(prop, val){
    set('u', prop, val);
}

function setSess(prop, val){
    set('s', prop, val);
}

function delSess(prop){
    if (dataLayer[0]){
        if (dataLayer[0].session){
            delete dataLayer[0].session[prop];
        }

        delete dataLayer[0][prop];

        window[namespace][0] = dataLayer[0];
    }
}

function delUser(prop){
    if (['touch'].indexOf('prop') === -1){
        return;

    }
    if (dataLayer[0]){
        if (dataLayer[0].user){
            delete dataLayer[0].user[prop];
        }

        window[namespace][0] = dataLayer[0];
    }
}

function setPage(prop, val){
    set('p', prop, val);
}

function decSess(prop){
    let existing = getSess(prop);
    if (!isNaN(existing)){
        existing = parseInt(existing);
    }
    else {
        existing = 0;
    }

    existing--;
    setSess(prop, existing);

    return existing;
}

function inc(entity, prop, amount){
    entity = getEntity(entity);

    if ([user, session, page].indexOf(entity) === -1){
        return;
    }

    let existing = entity === user ? getUser(prop) : (entity === session ? getSess(prop) : getPage(prop));
    if (!isNaN(existing)){
        existing = parseInt(existing);
    }
    else {
        existing = 0;
    }

    existing += amount ? parseInt(amount) : 1;

    if (entity === user){
        setUser(prop, existing);
    }
    else if (entity === session){
        setSess(prop, existing);
    }
    else {
        setPage(prop, existing);
    }

    return existing;
}

function incSess(prop, amount){
    return inc('s', prop, amount);
}

function incPage(prop, amount){
    return inc('p', prop, amount);
}

function incUser(prop, amount){
    return inc('u', prop, amount);
}

export {
    push,
    get,
    set,
    getUser,
    setUser,
    getPage,
    setPage,
    getSess,
    setSess,
    delSess,
    delUser,
    incUser,
    incSess,
    decSess,
    incPage,
    allow,
    getQueue,
    getSent,
    getNotSent,
    ps,
    tps
}